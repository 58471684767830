var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.signedUser)?_c('b-row',{attrs:{"align-v":"center","align-h":"end"}},[_c('b-col',{attrs:{"cols":"12","sm":"6","order":"3","order-sm":"1"}},[_c('b-row',[_c('p',{staticClass:"eh-web-app-header-logged-in-text"},[_vm._v(" "+_vm._s(_vm.$t("header.loggedWith", { name: _vm.signedUser.Name }))+" ")])]),(_vm.signedUser.Roles && _vm.signedUser.Roles.length > 1)?_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"eh-web-app-header-selector-label",attrs:{"cols":"12","lg":"2"}},[_vm._v(" "+_vm._s(_vm.$t("header.usedRoleView"))+": ")]),_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('fp-select',{staticClass:"eh-web-app-header-selector",attrs:{"items":_vm.signedUser.Roles,"value":_vm.signedUser.SelectedRole},on:{"change":_vm.changeRole},scopedSlots:_vm._u([{key:"element",fn:function(role){return [_vm._v(_vm._s(_vm.$t(_vm.roleList[role.SimpleArrayToObjectArrayValue].Text))+" ")]}}],null,false,3191585566)})],1)],1):_c('b-row',{staticClass:"eh-web-app-header-static-selector"},[_vm._v(" "+_vm._s(_vm.$t("header.usedRoleView"))+": "+_vm._s(_vm.roleList[_vm.signedUser.SelectedRole] ? _vm.$t(_vm.roleList[_vm.signedUser.SelectedRole].Text) : null)+" ")]),(
        _vm.signedUser.SelectedRole != 'Client' &&
        _vm.signedUser.Projects &&
        _vm.signedUser.Projects[_vm.signedUser.SelectedRole] &&
        _vm.signedUser.Projects[_vm.signedUser.SelectedRole].length > 0
      )?_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"eh-web-app-header-selector-label",attrs:{"cols":"12","lg":"2"}},[_vm._v(" "+_vm._s(_vm.$t("header.usedProject"))+": ")]),_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('fp-select',{staticClass:"eh-web-app-header-selector",attrs:{"valueKey":"ProjectId","items":_vm.signedUser.Projects[_vm.signedUser.SelectedRole],"value":_vm.signedUser.SelectedProject
              ? _vm.signedUser.SelectedProject.ProjectId
              : null},on:{"change":_vm.changeProject},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_vm._v(" "+_vm._s(e.ProjectName ? _vm.getTextByLanguage(e.ProjectName) : e.ProjectName)+" ")]}}],null,false,3618376888)})],1)],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","sm":"3","order":"2"}},[_c('fp-header-profile-picture-link',{attrs:{"profileImg":_vm.profileImg,"profileFillingRate":_vm.profileFillingRate,"signedProfilePicture":_vm.signedProfilePicture}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }